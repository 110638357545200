<template>
  <shape>
    <hero>
      <template #image>
        <img
          src="/content/images/illustrations/empty.png"
          alt="not-found"
        />
      </template>
      <template #title> 404! </template>
      <template #description> There’s nothing here, maybe make a coffee? </template>
      <template #buttons>
        <the-button
          secondary
          @click="goBack"
        >
          <template> Take me away from here! </template>
        </the-button>
      </template>
    </hero>
  </shape>
</template>
<script>
import IcChevronLeft from 'vue-material-design-icons/ChevronLeft'
import Hero from '@/components/shared/Hero.vue'
import Page from '@/components/base/page/Page.vue'
import PageSection from '@/components/base/page/PageSection.vue'

export default {
  name: 'page-not-found',
  props: ['theme'],
  components: { PageSection, Page, Hero, IcChevronLeft },
  computed: {
    loggedInPath() {
      return this.$route && this.$route.path.toLowerCase().includes('console')
    },
    hasHistory() {
      return window.history.length > 2
    },
  },
  methods: {
    goBack() {
      switch (true) {
        case this.hasHistory:
          this.$router.go(-1)
          break
        case this.loggedInPath:
          this.$router.push({ name: 'dashboard' })
          break
        default:
          this.$router.push('/')
      }
    },
  },
}
</script>
